import Vue from "vue";
import { GridPlugin, Page, Filter, Sort, Toolbar, Search, Edit, CommandColumn } from "@syncfusion/ej2-vue-grids";
import { CustomUrlAdaptor } from "@/syncfusion/CustomUrlAdaptor";
import { DataManager, Query } from "@syncfusion/ej2-data";
import SpinnerMixin from "@/mixins/spinner";
import TitleMixin from "@/mixins/title";
import GridMixin from "@/mixins/syncfusion/grid";
import SearchToolbar from "@/components/Templates/Attachment/LogSearchToolbar.vue";
Vue.use(GridPlugin);
export default Vue.extend({
    components: { "search-toolbar": SearchToolbar },
    mixins: [TitleMixin, SpinnerMixin, GridMixin],
    data() {
        return {
            title: this.$t("attachments.logs.logs"),
            data: new DataManager({
                url: process.env.VUE_APP_API_BASE_URL + (this.$route.params.ownerType && this.$route.params.ownerId && this.$route.params.attachmentId ?
                    `${this.$route.params.ownerType}/${this.$route.params.ownerId}/attachments/${this.$route.params.attachmentId}/logs/grid-data` :
                    `attachments/logs/grid-data`),
                adaptor: new CustomUrlAdaptor()
            }),
            sortOptions: {
                columns: [
                    { field: "LogDateTime", direction: "Descending" },
                ]
            },
            filterOptions: { type: "Menu" },
            pageSettings: { pageSize: 10 },
            toolbarOptions: ["Search"],
            searchOptions: {
                fields: ["OwnerName", "Name", "UserName"],
                operator: "contains"
            },
            editSettings: {
                allowEditing: false,
                allowAdding: false,
                allowDeleting: true,
                showDeleteConfirmDialog: true,
                mode: "Dialog"
            }
        };
    },
    provide: {
        grid: [CommandColumn, Page, Filter, Sort, Toolbar, Search, Edit]
    },
    methods: {
        search(args) {
            console.log(args);
            const grid = this.$refs["attachments-logs-grid"];
            const gridInstance = grid.ej2Instances;
            // Build query
            const query = new Query();
            if (args.ownerType != null)
                query.addParams("OwnerType", args.ownerType.toString());
            if (args.ownerType != null && args.ownerId != null)
                query.addParams("OwnerId", args.ownerId.toString());
            if (args.ownerType != null && args.attachmentTypeId != null)
                query.addParams("AttachmentTypeId", args.attachmentTypeId.toString());
            // Set the query and refresh the grid
            gridInstance.query = query;
            grid.refresh();
        }
    }
});
