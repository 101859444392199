import Vue from "vue";
import SpinnerMixin from "@/mixins/spinner";
import AppDropDownListOwnerType from "@/components/Inputs/AppDropDownListOwnerType.vue";
export default Vue.extend({
    components: {
        "app-dropdownlist-owner-type": AppDropDownListOwnerType
    },
    mixins: [SpinnerMixin],
    data() {
        return {
            loadCustomers: true,
            loadEmployees: true,
            owners: [],
            attachmentTypes: [],
            ownerType: null,
            ownerId: null,
            attachmentTypeId: null
        };
    },
    watch: {
        ownerType() {
            this.loadOwners();
            this.loadAttachmentTypes();
        }
    },
    methods: {
        async loadOwners() {
            this.ownerId = null;
            this.owners = [];
            try {
                if (this.ownerType == null || this.ownerType == 0)
                    return [];
                if (this.ownerType == 1) {
                    const customers = (await this.$store.dispatch("customer/getAll", {
                        load: this.loadCustomers
                    }));
                    this.loadCustomers = false;
                    this.owners = customers
                        .filter(customer => customer.deletedAt == undefined)
                        .map(customer => {
                        return {
                            id: customer.id,
                            name: `${customer.name} ${customer.surname}`
                        };
                    });
                }
                else if (this.ownerType == 2) {
                    const employees = (await this.$store.dispatch("employee/getAll", {
                        load: this.loadEmployees
                    }));
                    this.loadEmployees = false;
                    this.owners = employees
                        .filter(employee => employee.deletedAt == undefined)
                        .map(employee => {
                        return {
                            id: employee.id,
                            name: `${employee.name} ${employee.surname}`
                        };
                    });
                }
                else {
                    throw new Error(`The owner type ${this.ownerType} is not managed.`);
                }
            }
            catch (errors) {
                this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                    error: errors[0].message
                }));
            }
        },
        async loadAttachmentTypes() {
            this.attachmentTypeId = null;
            if (this.ownerType == null || this.ownerType == 0)
                return [];
            let attachmentTypes = (await this.$store.dispatch("attachmentType/getAll", {
                ownerType: this.ownerType
            }));
            attachmentTypes = attachmentTypes.filter(attachmentType => attachmentType.deletedAt == null);
            attachmentTypes.unshift({
                id: null,
                name: "Tutti"
            });
            this.attachmentTypes = attachmentTypes;
        },
        search() {
            this.$emit("search", {
                ownerType: this.ownerType,
                ownerId: this.ownerId,
                attachmentTypeId: this.attachmentTypeId
            });
        }
    }
});
